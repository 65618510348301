import React, { useState, useContext } from 'react';
import Endpoints from '@services/Endpoints';
import { Organization } from '@models/Organization';
import useSWR from 'swr';
import { ChevronDownIcon } from '@heroicons/react/solid';
import UserAvatar from '@components/auth/UserAvatar';
import { AcademicCapIcon } from '@heroicons/react/outline';
import PendingInvitesIcon from '@components/layout/PendingInvitesIcon';
import QuickOmniSearch from '@components/layout/QuickOmniSearch';
import Link from 'next/link';
import BannerFlyout from './BannerFlyout';
import NotificationBell from '@components/layout/NotificationBell';
import Logger from '@util/Logger';
import { AuthContext } from '@contexts/AuthContext';

const logger = Logger.make('LayoutBanner');

const Banner = () => {
    const { user } = useContext(AuthContext);
    const organization = user?.organization;
    const branding = organization?.branding;
    const headerLogoLink = branding?.header_logo_link;
    const customBgColor = branding?.banner_background;
    const headerLogo = branding?.header_logo;
    const highlightColor = branding?.highlight_color || '#7E51A9';
    const bannerStyle = {
        zIndex: 999,
        ...(customBgColor && { backgroundColor: customBgColor }),
    };

    const { data: allOrganizations } = useSWR<Organization[]>(() => (user ? Endpoints.organizations() : null));
    const [flyoutVisible, setFlyoutVisible] = useState(false);

    const handleFlyoutToggle = () => {
        setFlyoutVisible((prev) => !prev);
    };

    const closeFlyout = () => {
        setFlyoutVisible(false);
    };

    const handleSearch = (query: string) => {
        logger.debug('Searching for:', query);
    };

    // Determine what to display in the dropdown label
    let orgLabel;
    if (organization) {
        orgLabel = organization.name;
    } else if (allOrganizations === undefined) {
        orgLabel = 'Loading...';
    } else {
        orgLabel = user?.first_name || user?.email;
    }

    return (
        <div
            id="pluto-banner"
            className="fixed w-full ml-0 px-3 py-2 text-xs text-white bg-pluto-layout-purple"
            style={bannerStyle}
        >
            <div className="flex items-center justify-between pl-1" style={{ paddingTop: '2px' }}>
                <div className="relative w-[360px] h-[36px]">
                    {headerLogo && (
                        <Link
                            href={headerLogoLink || 'https://pluto.bio'}
                            target="_blank"
                            className="cursor-pointer absolute"
                        >
                            <img
                                id="custom-branded-banner-logo"
                                src={headerLogo}
                                alt="Custom Branding"
                                className="transition-opacity duration-500 ease-in-out ml-2 mt-1 max-w-[145px] opacity-100"
                            />
                        </Link>
                    )}

                    {/* Default Pluto Logo */}
                    <Link href="https://pluto.bio" target="_blank" className="cursor-pointer absolute">
                        <img
                            id="pluto-banner-logo"
                            src="https://cdn.bfldr.com/9AK17BV1/at/3vf537gqvmp9prnqq4z8qr7/Pluto_MainLogo_ONDark_2x?width=300&auto=webp"
                            alt="Pluto Bio"
                            className={`transition-opacity duration-500 ease-in-out ml-2 mt-1 w-[101px] ${
                                headerLogo ? 'opacity-0' : 'opacity-100'
                            }`}
                        />
                    </Link>
                </div>
                <div className="w-1/2 hidden md:block">
                    <div className="form-field no-margin">
                        <QuickOmniSearch
                            onSearch={handleSearch}
                            endpoint={Endpoints.lab.omniSearch()}
                            placeholder="Search experiments..."
                        />
                    </div>
                </div>
                <div id="dashboard-nav-right" className="flex items-center gap-1 justify-end w-full pr-0">
                    <div className="flex items-center gap-1 md:gap-3">
                        <Link href="/support" className="flex items-center">
                            <AcademicCapIcon className="opacity-70 hover:opacity-100 " color="#fff" width={'24'} />
                        </Link>
                        <PendingInvitesIcon />
                        <NotificationBell />

                        <div
                            className="h-6 border-l border-opacity-50 mx-3"
                            style={{
                                borderColor: highlightColor,
                                borderWidth: '1px',
                            }}
                        ></div>

                        <div
                            id="org-user-dropdown"
                            className="flex items-center pr-1 md:pr-4 cursor-pointer select-none"
                            onClick={handleFlyoutToggle}
                        >
                            <UserAvatar size="xxs" widthClass="w-6" heightClass="h-6" diameter="100%" disableTooltip />
                            <div
                                className="hidden md:block text-sm ml-3 text-white font-semibold max-w-72 overflow-hidden text-ellipsis whitespace-nowrap"
                                title={orgLabel}
                            >
                                {orgLabel}
                            </div>
                            <ChevronDownIcon
                                className={`ml-2 h-5 w-5 transition-transform ${flyoutVisible ? 'rotate-180' : ''}`}
                                style={{ color: highlightColor }}
                            />
                        </div>
                        {/* Flyout Menu */}
                        {flyoutVisible && user && (
                            <BannerFlyout
                                user={user}
                                organization={organization}
                                organizations={allOrganizations}
                                onClose={closeFlyout}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Banner;
