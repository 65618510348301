import React, { useState, useContext, useEffect } from 'react';
import cn from 'classnames';
import Link from 'next/link';
import { AuthContext } from '@contexts/AuthContext';
import { navigationItems } from '@constants/navigationItems';
import { SidebarLeftIcon } from '@components/icons/custom/SidebarLeftIcon';
import { SidebarRightIcon } from '@components/icons/custom/SidebarRightIcon';
import { Tooltip } from '@mui/material';

interface DashboardNavigationProps {
    collapsed: boolean;
    toggleCollapsed: () => void;
}

const DashboardNavigation: React.FC<DashboardNavigationProps> = ({ collapsed, toggleCollapsed }) => {
    const { user } = useContext(AuthContext);
    const organization = user?.organization;
    const branding = organization?.branding;
    const highlightColor = branding?.highlight_color || '#7E51A9';
    const nav_menu_hover = branding?.nav_menu_hover;
    const navBackgroundColorTop = branding?.nav_background_top;
    const [activeItem, setActiveItem] = useState<string | null>(null);
    const [hoveredFlyout, setHoveredFlyout] = useState<string | null>(null);
    const [showPowered, setShowPowered] = useState(false);

    useEffect(() => {
        if (branding?.header_logo) {
            const timeout = setTimeout(() => {
                setShowPowered(true);
            }, 1000);

            return () => clearTimeout(timeout);
        }
    }, [branding]);
    return (
        <>
            <div
                id="dashboard-navigation-left-rail"
                className={cn('transition-all duration-300 pt-2 relative', collapsed ? 'w-16' : 'w-52')}
            >
                {branding?.highlight_color && (
                    <style>{`
                    .nav-parent-item:hover {
                            background-color: ${highlightColor} !important;
                        }
                    `}</style>
                )}
                {nav_menu_hover && (
                    <style>{`
                    .nav-sub-menu:hover {
                        background-color: ${nav_menu_hover} !important;
                    }
                `}</style>
                )}
                <div
                    style={{
                        position: 'absolute',
                        width: '20px',
                        height: '20px',
                        top: '0',
                        right: '-20px',
                        zIndex: 1000,
                    }}
                >
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M19 0H0V19V20H0.0258641C0.00869236 19.6688 0 19.3354 0 19C0 8.50659 8.50659 0 19 0ZM20 0.0258641C19.6688 0.00869236 19.3354 0 19 0H20V0.0258641Z"
                            fill={navBackgroundColorTop || '#2E115C'}
                        />
                    </svg>
                </div>
                <nav className="relative flex flex-col text-white w-full py-2 pl-2" style={{ zIndex: 9999 }}>
                    {navigationItems.map((item) => (
                        <React.Fragment key={item.title}>
                            {/* Divider */}
                            {item.dividerAbove && (
                                <div
                                    className="border-t my-2 ml-3 w-7 hidden md:block"
                                    style={{
                                        borderColor: highlightColor,
                                        borderWidth: '1px',
                                    }}
                                ></div>
                            )}

                            {/* Navigation Item */}
                            <div className={item.hideOnMobile ? 'hidden md:block' : ''}>
                                {item.href ? (
                                    <Link
                                        href={item.href}
                                        className={cn(
                                            'nav-parent-item relative flex items-center px-4 py-3 rounded-l-xl hover:bg-pluto-layout-blue-hover',
                                        )}
                                        onMouseEnter={() => setActiveItem(item.title)}
                                        onMouseLeave={() => setActiveItem(null)}
                                    >
                                        {/* Icon */}
                                        <span className="flex-shrink-0 text-white">{item.icon}</span>

                                        {/* Title */}
                                        <span
                                            className={cn(
                                                'transition-all duration-300 whitespace-nowrap font-normal overflow-hidden text-sm text-white',
                                                collapsed ? 'max-w-0 opacity-0' : 'max-w-full opacity-100 ml-2',
                                            )}
                                            style={{ transition: 'max-width 0.3s, opacity 0.3s, margin-left 0.3s' }}
                                        >
                                            {item.title}
                                            {/* New Badge */}
                                            {item.isNew && (
                                                <span
                                                    className="w-2 h-2 rounded-full inline-block ml-2"
                                                    style={{ backgroundColor: highlightColor }}
                                                ></span>
                                            )}
                                        </span>
                                    </Link>
                                ) : (
                                    <div
                                        onMouseEnter={() => setActiveItem(item.title)}
                                        onMouseLeave={() => setActiveItem(null)}
                                        className={cn(
                                            'nav-parent-item relative flex items-center px-4 py-3 cursor-pointer rounded-l-xl hover:bg-pluto-layout-blue-hover',
                                        )}
                                    >
                                        {/* Icon */}
                                        <span className="flex-shrink-0">{item.icon}</span>

                                        {/* Title */}
                                        <span
                                            className={cn(
                                                'transition-all duration-300 whitespace-nowrap font-normal overflow-hidden text-sm text-white',
                                                collapsed ? 'max-w-0 opacity-0' : 'max-w-full opacity-100 ml-2',
                                            )}
                                            style={{ transition: 'max-width 0.3s, opacity 0.3s, margin-left 0.3s' }}
                                        >
                                            {item.title}
                                        </span>

                                        {/* Flyout Menu */}
                                        {activeItem === item.title && item.flyout && item.flyout.length > 0 && (
                                            <div
                                                className="absolute left-full rounded-xl p-2"
                                                style={{
                                                    top: '-15px',
                                                    marginLeft: '0px',
                                                    minWidth: '220px',
                                                    zIndex: 9999,
                                                }}
                                            >
                                                <div
                                                    className="bg-pluto-layout-blue text-white rounded-2xl py-4"
                                                    style={{
                                                        ...(branding?.highlight_color && {
                                                            backgroundColor: highlightColor,
                                                        }),
                                                    }}
                                                >
                                                    <ul className="list-none m-0 p-0 flex flex-col gap-0">
                                                        {item.flyout.map((flyoutItem) => (
                                                            <li
                                                                key={flyoutItem.label}
                                                                onMouseEnter={() => setHoveredFlyout(flyoutItem.label)}
                                                                onMouseLeave={() => setHoveredFlyout(null)}
                                                                className={cn(
                                                                    'nav-sub-menu flex items-center gap-0 px-2 py-1 rounded hover:text-white',
                                                                    hoveredFlyout === flyoutItem.label &&
                                                                        'bg-pluto-layout-blue-hover',
                                                                )}
                                                            >
                                                                <Link
                                                                    href={flyoutItem.href}
                                                                    className="text-white whitespace-nowrap flex-grow hover:text-white text-sm font-normal py-2 pl-4"
                                                                >
                                                                    {flyoutItem.label}
                                                                </Link>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        </React.Fragment>
                    ))}
                </nav>
            </div>
            {branding?.header_logo && (
                <div
                    className="absolute bottom-16 flex items-center justify-center transition-all duration-500 ease-in-out"
                    style={{
                        left: collapsed ? '0.5rem' : '1.25rem',
                        width: collapsed ? '3rem' : '6rem',
                        height: '2.5rem',
                        position: 'absolute',
                        opacity: showPowered ? 1 : 0, // controlled by state
                    }}
                >
                    <>
                        {/* Collapsed Version */}
                        <Tooltip title="Powered by Pluto Bio" arrow placement="top-start">
                            <Link
                                href="https://pluto.bio"
                                target="_blank"
                                className="absolute"
                                style={{
                                    transition: 'opacity 0.5s ease, width 0.5s ease',
                                    opacity: collapsed ? 1 : 0,
                                    width: collapsed ? '26px' : '0px',
                                }}
                            >
                                <img
                                    src="https://cdn.bfldr.com/9AK17BV1/at/vg8v9c59nn899j4z3hb9n4pm/Pluto_Mark_OnDarkAlt_x2?width=26&auto=webp"
                                    alt="Pluto Bio"
                                    className="cursor-pointer"
                                />
                            </Link>
                        </Tooltip>
                        {/* Expanded Version */}
                        <Link
                            href="https://pluto.bio"
                            target="_blank"
                            className="absolute"
                            style={{
                                transition: 'opacity 0.5s ease, width 0.5s ease',
                                opacity: collapsed ? 0 : 1,
                                // width: collapsed ? '0px' : '101px',
                            }}
                        >
                            <div className="text-white pb-2" style={{ fontSize: '11px', opacity: collapsed ? 0 : 1 }}>
                                Powered by
                            </div>
                            <img
                                src="https://cdn.bfldr.com/9AK17BV1/at/3vf537gqvmp9prnqq4z8qr7/Pluto_MainLogo_ONDark_2x?width=300&auto=webp"
                                alt="Pluto Bio"
                                className="cursor-pointer"
                                style={{ width: '96px', height: '30.3px' }}
                            />
                        </Link>
                    </>
                </div>
            )}

            {/* Toggle Button */}
            <button
                onClick={toggleCollapsed}
                className="absolute bottom-6 right-3 w-10 h-10 shadow-md flex items-center justify-center"
            >
                <Tooltip title={collapsed ? 'Expand sidebar' : 'Collapse sidebar'} arrow placement="right-start">
                    <span>
                        {collapsed ? (
                            <SidebarRightIcon width={22} height={22} className="text-white" />
                        ) : (
                            <SidebarLeftIcon width={22} height={22} className="text-white" />
                        )}
                    </span>
                </Tooltip>
            </button>
        </>
    );
};

export default DashboardNavigation;
