import cn from 'classnames';
import React, { CSSProperties, ReactNode, useState } from 'react';
import DefaultAvatar from 'boring-avatars';
import { Tooltip } from '@mui/material';
import Image from 'next/image';

export type AvatarSize = 'xxs' | 'xs' | 'sm' | 'md' | 'lg';

const selectedClasses = 'ring-4';
export type AvatarCircleProps = {
    classes?: string | string[] | null | Record<string, boolean | string | number>;
    imageUrl?: string | null;
    image?: ReactNode;
    title?: string;
    shadow?: boolean;
    className?: string;
    userId?: string | null;
    size?: AvatarSize;
    widthClass?: string;
    heightClass?: string;
    selected?: boolean;
    onSelect?: () => void;
    children?: ReactNode | null;
    growOnSelected?: boolean;
    diameter?: number | string;
    disableTooltip?: boolean;
    variant?: 'rounded' | 'circle';
};

const AvatarColorPalette = {
    blue: ['#3343AB', '#5D6CCE', '#7E8AD8', '#B7BEEA', '#D6DAF3'],
    cyan: ['#337DAB', '#5DA3CE', '#7EB5D8', '#B7D6EA', '#D6E8F3'],
    violet: ['#78429B', '#9A6BC0', '#B689CD', '#D7BEE3', '#E8DAEF'],
};
const paletteCount = Object.keys(AvatarColorPalette).length;
const palettes = Object.values(AvatarColorPalette);

function stringToNumber(input: string) {
    return input.split('').reduce((total, c) => {
        const charCode = c.charCodeAt(0);
        if (isNaN(charCode)) {
            return total;
        }
        return charCode + total;
    }, 0);
}

export function getColorsForUserId(userId?: string | null): string[] {
    if (!userId) {
        return AvatarColorPalette.violet;
    }
    const num = stringToNumber(userId);
    const index = num % paletteCount;
    return palettes[index] ?? AvatarColorPalette.blue;
}

export const AvatarCircle = (props: AvatarCircleProps) => {
    const {
        classes = [],
        selected = false,
        imageUrl,
        title,
        children,
        size = 'sm',
        growOnSelected = false,
        userId,
        className,
        shadow = true,
        disableTooltip,
        variant = 'circle',
    } = props;
    let { widthClass = '', heightClass = '', diameter = '' } = props;
    const image = props.image;
    const [loadingError, setLoadingError] = useState(false);
    const style: CSSProperties = diameter ? { width: `${diameter}px`, height: `${diameter}px` } : {};
    if (!diameter && !widthClass && !heightClass) {
        switch (size) {
            case 'xxs':
                diameter = 28;
                heightClass = 'h-6';
                widthClass = 'w-6';
                break;
            case 'xs':
                diameter = 32;
                heightClass = 'h-8';
                widthClass = 'w-8';
                break;
            case 'sm':
                diameter = 48;
                heightClass = 'w-12';
                widthClass = 'h-12';
                break;
            case 'md':
                diameter = 64;
                heightClass = 'w-16';
                widthClass = 'h-16';
                break;
            case 'lg':
                diameter = 80;
                heightClass = 'w-16 md:w-20';
                widthClass = 'h-16 md:h-20';
                break;
        }
    }

    const handleImageLoadingError = () => {
        setLoadingError(true);
    };

    const diameterNumber = typeof diameter === 'string' ? parseInt(diameter, 10) : diameter;

    const $img = (
        <div
            onClick={() => props.onSelect?.()}
            className={cn(
                'flex shrink-0 items-center justify-center overflow-hidden translate-x-0 translate-y-0',
                widthClass,
                heightClass,
                className,
                classes,
                {
                    'cursor-pointer': !!props.onSelect,
                    [selectedClasses]: selected,
                    'hover:scale-110': !selected && growOnSelected,
                    'scale-125 ': selected && growOnSelected,
                    'transition-transform duration-300': growOnSelected,
                    shadow: shadow,
                    'rounded-full': variant === 'circle',
                    'rounded-2xl': variant === 'rounded',
                },
            )}
            style={{ ...style }}
        >
            {image ?? image}
            {imageUrl && !loadingError ? (
                <Image
                    src={imageUrl}
                    onError={handleImageLoadingError}
                    alt={title ?? 'Profile Image'}
                    className={cn('h-full w-full shrink-0 object-cover')}
                    width={diameterNumber}
                    height={diameterNumber}
                />
            ) : (
                <div className="pointer-events-none relative h-full w-full">
                    <DefaultAvatar
                        size={diameter}
                        name={userId ?? title ?? 'Profile Image'}
                        variant={variant === 'circle' ? 'bauhaus' : 'marble'}
                        colors={getColorsForUserId(userId ?? title ?? 'Profile Image')}
                        square={variant === 'rounded'}
                    />
                </div>
            )}
            {!imageUrl && !image && children}
        </div>
    );

    if (!title || disableTooltip) {
        return $img;
    }

    return (
        <Tooltip title={title} placement="top" arrow>
            {$img}
        </Tooltip>
    );
};
