import { useEffect } from 'react';
import { useWebSocket } from '@contexts/WebSocketContext';

export const useWebSocketEvent = (type: string, handler: (data: any) => void) => {
    const { subscribe, unsubscribe } = useWebSocket();

    useEffect(() => {
        subscribe(type, handler);
        return () => unsubscribe(type, handler);
    }, [type, handler, subscribe, unsubscribe]);
};
