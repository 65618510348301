import React, { useContext } from 'react';
import { AuthContext } from '@contexts/AuthContext';
import DefaultLayout, { DefaultLayoutProps } from '@layouts/DefaultLayout';
import { PropsWithChildren, ReactNode, useEffect, useMemo } from 'react';
import DashboardNavigation from '@components/layout/DashboardNavigation';
import cn from 'classnames';
import LoginForm from '@components/LoginForm';
import Card from '@components/Card';
import ProjectArchiveConfirmModal from '@components/projects/ProjectArchiveConfirmModal';
import useAuth from '@hooks/useAuth';
import LoadingMessage from '@components/LoadingMessage';
import ProjectSharingDialog from '@components/sharing/ProjectSharingDialog';
import { LabSpaceContextProvider } from '@contexts/LabSpaceContext';
import LabspaceExperimentSharingDialog from '@components/sharing/LabspaceExperimentSharingDialog';
import TrialBanner from '@components/trial/TrialBanner';
import { useTrialContext } from '@contexts/TrialContext';
import { useRouter } from 'next/router';
import Banner from '@components/banner/Banner';
import { useSidebar } from '@contexts/SidebarContext';

const DefaultAuthComponent = () => {
    return (
        <DefaultLayout maxWidth="lg">
            <Card>
                <h3 className="mb-4 text-center text-3xl font-semibold tracking-tight">
                    {"There's so much more to explore on Pluto!"}
                </h3>
                <p className="mb-8 text-center text-default">Log in to your account to view this page</p>
                <LoginForm />
            </Card>
        </DefaultLayout>
    );
};

export type DashboardLayoutProps = PropsWithChildren<
    DefaultLayoutProps & { authRequired?: boolean; authComponent?: ReactNode; fixedViewport?: boolean }
>;
const DashboardLayout = ({
    children,
    className,
    container = false,
    noMargin,
    authComponent = <DefaultAuthComponent />,
    ...props
}: DashboardLayoutProps) => {
    const { user } = useContext(AuthContext);
    const organization = user?.organization;
    const branding = organization?.branding;
    const navBackgroundColorTop = branding?.nav_background_top;
    const navBackgroundColorBottom = branding?.nav_background_bottom;
    const router = useRouter();
    const { isLoggedIn, authReady } = useAuth();
    const { trialExpired } = useTrialContext();

    const { collapsed, toggleCollapsed } = useSidebar();

    const $body = useMemo(() => {
        if (!authReady) return <LoadingMessage />; // Authentication not ready
        if (authReady && !isLoggedIn) return authComponent; // Not logged in, show authComponent
        return children; // Logged in, show content
    }, [authReady, isLoggedIn, authComponent, children]);

    useEffect(() => {
        if (trialExpired && authReady && isLoggedIn) {
            router.push('https://pluto.bio/get-info');
        }
    }, [trialExpired, authReady, isLoggedIn]);

    if (trialExpired) {
        return null;
    }

    const sidebarStyle = {
        paddingTop: '54px',
        zIndex: 998,
        ...(navBackgroundColorTop &&
            navBackgroundColorBottom && {
                background: `linear-gradient(to bottom, ${navBackgroundColorTop} 54px, ${navBackgroundColorBottom})`,
            }),
    };

    return (
        <DefaultLayout
            className={cn(className, 'max-w-screen min-h-screen')}
            container={container}
            {...props}
            noMargin={noMargin}
        >
            <LabSpaceContextProvider>
                <Banner />
                <TrialBanner innerClassName="" />
                <div>
                    {/* Sidebar - Positioning to account for banner */}
                    <div
                        className="flex-shrink-0 from-pluto-layout-purple to-pluto-layout-darkpurple bg-gradient-to-b fixed h-screen"
                        style={sidebarStyle}
                    >
                        <DashboardNavigation collapsed={collapsed} toggleCollapsed={toggleCollapsed} />
                    </div>

                    {/* Content Area - Positioning dependent on if nav is collapsed */}
                    <div
                        className={cn(
                            'flex-grow pt-10 overflow-hidden transition-all duration-300',
                            collapsed ? 'ml-16' : 'ml-52',
                        )}
                    >
                        {$body}
                    </div>
                </div>
                {/* Modals */}
                <ProjectArchiveConfirmModal />
                <LabspaceExperimentSharingDialog />
                <ProjectSharingDialog />
            </LabSpaceContextProvider>
        </DefaultLayout>
    );
};

export default DashboardLayout;
