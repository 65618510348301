// components/PendingInvitesIcon.tsx

import React, { useEffect, useState, useRef } from 'react';
import { MailIcon } from '@heroicons/react/outline';
import usePendingInvites from '@hooks/usePendingInvites';
import { useLabSpaceContext } from '@contexts/LabSpaceContext';

const PendingInvitesIcon = () => {
    const { invites } = usePendingInvites();
    const { setPendingInvitesModalOpen } = useLabSpaceContext();

    const [animate, setAnimate] = useState(false);
    const pendingInvitesCountRef = useRef(0);

    // Calculate the number of active pending invites
    const pendingInvitesCount = (invites ?? []).filter((invite) => invite.is_active).length;

    useEffect(() => {
        // Detect when pendingInvitesCount goes from 0 to a positive number
        if (pendingInvitesCountRef.current === 0 && pendingInvitesCount > 0) {
            // New pending invites have arrived
            setAnimate(true);
            // Reset the animation after it's done
            setTimeout(() => setAnimate(false), 1000); // Adjust duration to match CSS animation
        }
        // Update the ref to the current pendingInvitesCount
        pendingInvitesCountRef.current = pendingInvitesCount;
    }, [pendingInvitesCount]);

    const handleClick = (e: React.MouseEvent) => {
        e.preventDefault();
        setPendingInvitesModalOpen(true);
    };

    return (
        <button
            onClick={handleClick}
            className="relative flex items-center focus:outline-none"
            aria-label="Pending invitations"
        >
            <MailIcon
                className={`w-6 h-6 text-white opacity-70 ${
                    pendingInvitesCount > 0 ? 'text-white opacity-100' : ''
                } hover:text-white ${animate ? 'animate-wiggle' : ''}`}
            />
            {pendingInvitesCount > 0 && (
                <span className="absolute top-0 right-0 inline-flex items-center justify-center px-1.5 py-0.5 text-xs font-bold leading-none text-white transform translate-x-1/2 -translate-y-1/2 bg-red-600 rounded-full">
                    {pendingInvitesCount}
                </span>
            )}
        </button>
    );
};

export default PendingInvitesIcon;
