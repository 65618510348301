import { useCallback, useEffect, useRef, useState } from 'react';
import { TimeoutValue } from '@util/ObjectUtil';
import Logger from '@util/Logger';
import { getErrorDetails } from '../api/ApiTypes';

const logger = Logger.make('useSimpleClipboard');

type Props = { successTimeoutMs?: number };
const useSimpleClipboard = (props?: Props) => {
    const { successTimeoutMs = 2500 } = props ?? {};
    const [showSuccess, setShowSuccess] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const timeoutRef = useRef<TimeoutValue | null>(null);
    useEffect(() => {
        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };
    }, []);

    const handleCopySuccess = useCallback(() => {
        setShowSuccess(true);
        logger.debug('setting copy to clipboard success');
        if (successTimeoutMs) {
            logger.debug('copy success timeout is ', successTimeoutMs);
            timeoutRef.current = setTimeout(() => {
                logger.debug('removing copy to clipboard success');
                setShowSuccess(false);
                timeoutRef.current = null;
            }, successTimeoutMs);
        }
    }, [successTimeoutMs]);

    const copyTextToClipboard = async (text: string) => {
        try {
            if (navigator.clipboard) {
                await navigator.clipboard.writeText(text);
                handleCopySuccess();
            } else {
                logger.error(new Error('Unsupported copy method - navigator.clipboard is undefined'));
                setError('Copy to clipboard is not supported on your device.');
            }
        } catch (error) {
            logger.error(error);
            setError(`Unable to cpy to clipboard: ${getErrorDetails(error).message ?? 'unknown error'}`);
        }
    };

    return { copyTextToClipboard, showSuccess, error };
};

export default useSimpleClipboard;
