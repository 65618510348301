import { PropsWithChildren, useMemo } from 'react';
import copy from '@copy/Copy';
import cn, { Argument as ClassValue } from 'classnames';
import BaseMeta from '@layouts/BaseMeta';
import { HubspotProvider } from 'next-hubspot';

export type MaxWidth = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl' | '4xl' | '5xl' | '6xl' | '7xl';

export type LayoutMetaProps = {
    title?: string;
    titleFull?: string;
    description?: string;
    metaImageUrl?: string;
    metaImageAltText?: string;
    metaVideoUrl?: string;
    ogType?: string;
};

export type DefaultLayoutProps = PropsWithChildren<
    LayoutMetaProps & {
        showLogo?: boolean;
        className?: ClassValue;
        container?: boolean;
        noMargin?: boolean;
        noMarginTop?: boolean;
        maxWidth?: MaxWidth;
        fullWidth?: boolean;
        noMetadata?: boolean;
    }
>;

export function getMaxWidthClasses(maxWidth?: MaxWidth | null) {
    return {
        'max-w-sx': maxWidth === 'xs',
        'max-w-sm': maxWidth === 'sm',
        'max-w-md': maxWidth === 'md',
        'max-w-lg': maxWidth === 'lg',
        'max-w-xl': maxWidth === 'xl',
        'max-w-2xl': maxWidth === '2xl',
        'max-w-3xl': maxWidth === '3xl',
        'max-w-4xl': maxWidth === '4xl',
        'max-w-5xl': maxWidth === '5xl',
        'max-w-6xl': maxWidth === '6xl',
        'max-w-7xl': maxWidth === '7xl',
    };
}

export default function DefaultLayout({
    children,
    className,
    container = true,
    title,
    titleFull,
    description = copy.siteDescription,
    metaImageUrl = copy.siteMetaImageUrl,
    metaImageAltText = `${copy.companyName} Logo`,
    ogType,
    noMargin = false,
    maxWidth,
    fullWidth = false,
    noMetadata = false,
}: DefaultLayoutProps): JSX.Element {
    const widthClasses = fullWidth ? {} : getMaxWidthClasses(maxWidth);

    const metaTitle = useMemo(
        () => (titleFull ?? (title ? `${title} | ${copy.companyName}` : copy.siteTitleLong))?.substring(0, 60),
        [titleFull, title],
    );

    return (
        <>
            <HubspotProvider>
                {!noMetadata && (
                    <BaseMeta
                        title={metaTitle}
                        description={description}
                        imageUrl={metaImageUrl}
                        imageAltText={metaImageAltText}
                        ogType={ogType}
                    />
                )}
                <div className="h-full min-h-screen">
                    <div className="flex h-full flex-col">
                        <div
                            className={cn(
                                'flex h-full flex-grow flex-col',
                                {
                                    container: !maxWidth && container && !fullWidth,
                                    'mx-auto': maxWidth && !fullWidth,
                                    'mb-24 md:mb-0': !noMargin,
                                    ...widthClasses,
                                },
                                className,
                            )}
                        >
                            <main>{children}</main>
                        </div>
                    </div>
                </div>
            </HubspotProvider>
        </>
    );
}
