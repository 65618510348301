import usePendingInvites from '@hooks/usePendingInvites';
import { pluralize } from '@util/ObjectUtil';
import PendingInviteItem from '@components/PendingInviteItem';
import LoadingMessage from '@components/LoadingMessage';
import { Invite } from '@models/Invitation';
import useApi from '@hooks/useApi';
import Endpoints from '@services/Endpoints';
import Logger from '@util/Logger';
import React, { useState } from 'react';
import useAuth from '@hooks/useAuth';

const logger = Logger.make('PendingInvitesView');

const PendingInvitesView = () => {
    const [showPastInvites, setShowPastInvites] = useState(false);
    const [recentlyAcceptedIds, setRecentlyAcceptedIds] = useState<string[]>([]);
    const { invites, loading, mutate: mutateInvite } = usePendingInvites();
    const [acceptingInvite, setAcceptingInvite] = useState<Invite | null>(null);
    const { revalidateUser } = useAuth();
    const filteredInvites = (invites ?? []).filter(
        (invite) => invite.is_active || showPastInvites || recentlyAcceptedIds.includes(invite.uuid),
    );

    const pendingInvites = (invites ?? []).filter((invite) => invite.is_active);

    const api = useApi();

    if (loading) {
        return (
            <div className="flex items-center justify-center py-12">
                <LoadingMessage message="Loading invites..." />
            </div>
        );
    }

    const handleAccept = async (invite: Invite) => {
        try {
            setAcceptingInvite(invite);
            setRecentlyAcceptedIds((current) => {
                if (current.includes(invite.uuid)) {
                    return current;
                }
                return [...current, invite.uuid];
            });
            await mutateInvite(async (currentData) => {
                const current: Invite[] = currentData ? [...currentData] : [];
                const acceptResult = await api.post(Endpoints.user.invite.accept({ inviteId: invite.uuid }));
                logger.info('acceptResult', acceptResult);
                const existingIndex = current.findIndex((i) => i.uuid === invite.uuid);
                if (existingIndex >= 0) {
                    current[existingIndex] = { ...invite, is_active: false };
                } else {
                    current.push({ ...invite, is_active: false });
                }

                return current;
            });

            if (invite.invite_type === 'organization') {
                // mutate user info
                await revalidateUser();
                location.reload();
            }
        } catch (error) {
            console.error(error);
        } finally {
            setAcceptingInvite(null);
        }
    };

    return (
        <div className="space-y-8">
            <div>
                <div className="flex flex-col items-center justify-center">
                    {pendingInvites.length > 0 ? (
                        <p className="text-lg font-semibold">
                            You have {pendingInvites.length}{' '}
                            {pluralize(pendingInvites.length, 'pending invite', 'pending invites')}
                        </p>
                    ) : (
                        <p className="text-lg font-semibold">You have no pending invites</p>
                    )}
                    <div>
                        <label className="block pt-1">
                            <input
                                type="checkbox"
                                className="-mt-0.5 rounded text-indigo-500"
                                checked={showPastInvites}
                                onChange={(e) => {
                                    setShowPastInvites(e.target.checked);
                                }}
                            />
                            <div className="ml-2 inline-block">Show previously accepted</div>
                        </label>
                    </div>
                </div>
            </div>
            <div className="space-y-2">
                {filteredInvites.map((invite) => {
                    return (
                        <PendingInviteItem
                            invite={invite}
                            key={invite.uuid}
                            className="rounded-xl border border-indigo-200 p-4"
                            handleAccept={handleAccept}
                            loading={acceptingInvite?.uuid === invite.uuid}
                        />
                    );
                })}
            </div>
        </div>
    );
};

export default PendingInvitesView;
