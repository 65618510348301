import { Form, Formik, FormikHelpers } from 'formik';
import { Collapse, FormControl, MenuItem, Select } from '@mui/material';
import { Invite, InviteExistingUser } from '@models/Invitation';
import React, { useMemo, useState } from 'react';
import { defaultRoleLevels, PermissionObjectName, RoleLevel } from '@models/Permission';
import { FormikFieldError } from '@components/forms/FieldError';
import Button from '@components/Button';
import * as Yup from 'yup';
import { Alert } from '@mui/material';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import RoleDescriptions from '@components/dashboard/RoleDescriptions';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import { Schemas } from '@components/forms/Forms';
import useAuth from '@hooks/useAuth';
import { isDefined } from '@util/TypeGuards';
import cn from 'classnames';
import CopyTextInput from '@components/forms/CopyTextInput';
import { SharingMember } from '@/src/models/User';
import Picker from '@components/forms/Picker';
import { useRouter } from 'next/router';
import { truncateText } from '@/src/util/textUtil';

const styles = {
    root: {
        '& .MuiAlert-message': {
            width: '100%',
        },
    },
};

export type FormValues = { member_ids: string[]; role_type: RoleLevel };
export type Props = {
    roleLevels?: RoleLevel[];
    itemType: PermissionObjectName;
    onSubmit: (values: InviteExistingUser) => Promise<{ success: boolean; error: string }>;
    availableMembers: SharingMember[];
    closeModal: () => void;
};

const initialValues: InviteExistingUser = { member_ids: [], role_type: RoleLevel.viewer };
type Option = { label: string; value: string };

const Schema = Yup.object({
    users: Yup.array(
        Yup.object({
            email: Schemas.email,
        }),
    ),
});

const InviteExistingMemberForm = ({
    onSubmit,
    roleLevels = defaultRoleLevels,
    itemType,
    availableMembers,
    closeModal,
}: Props) => {
    const { user } = useAuth();
    const router = useRouter();
    const hasOrganization = isDefined(user?.organization);
    const [rolesExpanded, setRolesExpanded] = useState(false);

    const availableMemberValues: Option[] = useMemo(() => {
        return availableMembers.map((member) => {
            return { value: member.uuid, label: member.email ?? member.first_name + ' ' + member.last_name };
        });
    }, [availableMembers]);

    const handleSubmit = async (values: FormValues, formik: FormikHelpers<FormValues>) => {
        const { error, success } = await onSubmit(values);

        if (!!error) formik.setStatus({ error });
        if (!!success) formik.setStatus({ success: 'Member successfully added' });

        formik.setSubmitting(false);
    };

    const goToTeamTab = () => {
        closeModal();
        router.replace('/team');
    };

    return (
        <div>
            <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={Schema}>
                {({ values, handleChange, isSubmitting, status }) => {
                    return (
                        <Form>
                            <div className="">
                                <div className="grid grid-cols-3 gap-x-2 pl-1">
                                    <div className="field-label no-margin col-span-2"></div>
                                    <div className="field-label no-margin mb-0 flex items-center">
                                        <span className="mr-1">Role</span>
                                        <InfoOutlined
                                            fontSize="small"
                                            className="relative cursor-pointer text-indigo-600"
                                            onClick={() => setRolesExpanded(!rolesExpanded)}
                                        />
                                    </div>
                                </div>
                                <Collapse in={rolesExpanded}>
                                    <RoleDescriptions
                                        roles={roleLevels}
                                        itemType={itemType}
                                        className="rounded-lg bg-indigo-100 p-4 text-default"
                                    />
                                </Collapse>
                                <div className="grid w-full grid-cols-3 gap-x-2 gap-y-2">
                                    <div className="col-span-2 -mt-[6.5px] pl-0.5">
                                        <Picker
                                            name="member_ids"
                                            noOptionsText="No members found"
                                            options={availableMemberValues}
                                            placeholder="Select a user"
                                            itemName="member"
                                            showCopyButton={false}
                                            disableCustomValues
                                        />
                                    </div>
                                    <div className="flex space-x-2">
                                        <FormControl variant="outlined" fullWidth>
                                            <Select
                                                IconComponent={KeyboardArrowDownRoundedIcon}
                                                margin="dense"
                                                name="role_type"
                                                onChange={handleChange}
                                                value={values.role_type}
                                            >
                                                {roleLevels.map((roleLevel) => (
                                                    <MenuItem key={roleLevel} value={roleLevel}>
                                                        <div className="flex max-w-sm flex-row items-center justify-start whitespace-normal capitalize sm:max-w-lg">
                                                            {roleLevel}
                                                        </div>
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            <FormikFieldError name="role_type" />
                                        </FormControl>
                                    </div>
                                </div>
                            </div>
                            <div className="mb-2 flex w-full justify-end pt-4">
                                <Button
                                    disabled={isSubmitting || !values.member_ids.length}
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                >
                                    Add
                                </Button>
                            </div>
                            {hasOrganization &&
                                [PermissionObjectName.project, PermissionObjectName.experiment].includes(itemType) && (
                                    <div className="mb-2 text-sm">
                                        Inviting collaborators here adds them only to this {itemType}.{' '}
                                        <span>
                                            To invite someone to your team or organization, please visit the{' '}
                                            <span
                                                className="cursor-pointer font-semibold text-primary"
                                                onClick={goToTeamTab}
                                            >
                                                Team tab
                                            </span>
                                            .
                                        </span>
                                    </div>
                                )}
                            {status?.error && (
                                <Alert severity="error" className="text-error">
                                    {status.error}
                                </Alert>
                            )}
                            {status?.success && (
                                <Alert severity="success" className={cn(styles.root, 'text-success')}>
                                    <div className="flex items-center">{status.success}</div>
                                    <div>
                                        {status?.invites?.map((invite: Invite, index: number) => (
                                            <div
                                                key={invite.uuid}
                                                className={cn('row flex items-center', {
                                                    'mb-2':
                                                        status?.invites.length > 1 &&
                                                        index !== status?.invites.length - 1,
                                                })}
                                            >
                                                <span className="mr-2">{truncateText(invite.to_email, 50)}</span>
                                                <CopyTextInput
                                                    value={invite.invite_link}
                                                    noMargin
                                                    inputClasses="!text-xs text-default opacity-80"
                                                />
                                            </div>
                                        ))}
                                    </div>
                                </Alert>
                            )}
                        </Form>
                    );
                }}
            </Formik>
        </div>
    );
};

export default InviteExistingMemberForm;
