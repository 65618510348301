import useApi from '@hooks/useApi';
import React, { ReactNode, useState } from 'react';
import useExperimentPermissions from '@hooks/useExperimentPermissions';
import useSWR from 'swr';
import { SharingMember } from '@models/User';
import Endpoints from '@services/Endpoints';
import { hasPermission } from '@util/PermissionUtil';
import { PermissionName, PermissionObjectName, defaultRoleLevels } from '@models/Permission';
import SharingDialog from '@components/sharing/SharingDialog';
import Logger from '@util/Logger';
import { PaginationResponse } from '@services/EndpointUtil';
import Experiment from '@models/Experiment';
import useAuth from '@hooks/useAuth';
import useExperimentCache from '@hooks/useExperimentCache';
import useMatchMutate from '@hooks/useMatchMutate';
import { InviteExistingUser } from '@/src/models/Invitation';
import { getErrorDetails } from '@/src/api/ApiTypes';

const logger = Logger.make('ExperimentSharingDialog');
type Props = { experiment: Experiment | null | undefined; onClose: () => void; open: boolean };
const ExperimentSharingDialog = ({ experiment: _experiment, open, onClose }: Props) => {
    const api = useApi();
    const { user } = useAuth();
    const { refreshExperiment } = useExperimentCache();
    const { startsWithMutate } = useMatchMutate();
    const [error, setError] = useState<string | ReactNode | null>(null);

    const { data: experiment } = useSWR<Experiment>(
        () => _experiment?.uuid && Endpoints.lab.experiment.base(_experiment?.uuid),
    );

    const permissions = useExperimentPermissions(experiment);

    const {
        data,
        error: membersError,
        mutate,
    } = useSWR<PaginationResponse<SharingMember>>(
        () =>
            experiment && permissions.members.canView
                ? Endpoints.lab.experiment.members({ experimentId: experiment.uuid })
                : null,
        { revalidateOnMount: true },
    );

    const { data: orgMembers, error: orgMembersError } = useSWR<PaginationResponse<SharingMember>>(
        () =>
            user?.organization && permissions.members.canView
                ? Endpoints.organization.members({ organizationId: user.organization.uuid })
                : null,
        { revalidateOnMount: true },
    );

    const members = data?.items;
    const availableMembers = orgMembers?.items;
    const membersCount = data?.count;
    const membersLoading = !data && !membersError && !orgMembers && !orgMembersError;

    const handleClose = () => {
        // updateExperimentSharingModal({ open: false });
        onClose();
    };

    const handleInviteExistingUsers = async (values: InviteExistingUser) => {
        if (!experiment) return { success: false, error: 'Experiment does not exist' };
        const response = { success: false, error: '' };
        try {
            const payload = { member_ids: values.member_ids, role_shortname: values.role_type };
            await api.post(Endpoints.lab.experiment.members({ experimentId: experiment.uuid }), payload);
            await mutate();
            response.success = true;
        } catch (error) {
            logger.error(error);
            response.error = 'Failed to invite users';
        } finally {
            return response;
        }
    };

    const handleRemoveMember = async (member: SharingMember) => {
        setError(null);
        if (!experiment) {
            setError('Can not remove member - No experiment was found.');
            return;
        }
        try {
            const updatedMembers = (members ?? [])?.filter((m) => m.uuid !== member.uuid);
            await mutate({ items: updatedMembers, count: updatedMembers.length }, false);
            await api.fetcher<void>(
                Endpoints.lab.experiment.member({
                    experimentId: experiment.uuid,
                    memberId: member.uuid,
                }),
                { method: 'DELETE' },
            );
        } catch (error) {
            logger.error(error);
            setError(`Unable to remove member: ${getErrorDetails(error).message ?? 'An unknown error occurred'}`);
        } finally {
            await mutate();
        }
    };

    const handleSaveMember = async (member: SharingMember) => {
        setError(null);
        if (!experiment) {
            setError('Can not save member - No project was found.');
            return;
        }
        try {
            await api.fetcher<void>(
                Endpoints.lab.experiment.member({
                    experimentId: experiment.uuid,
                    memberId: member.uuid,
                }),
                { method: 'PUT', body: JSON.stringify(member) },
            );
            const index = members?.findIndex((m) => m.uuid === member.uuid) ?? -1;
            if (index >= 0 && members) {
                const updatedMembers = [...members];
                updatedMembers[index] = member;
                await mutate({ items: updatedMembers, count: updatedMembers.length }, false);
            }
        } catch (error) {
            logger.error(error);
            setError(`Unable to save member: ${getErrorDetails(error).message ?? 'An unknown error occurred'}`);
        } finally {
            await mutate();
        }
    };

    if (!experiment) {
        return null;
    }

    const canRemoveMembers = hasPermission(experiment, { requires: PermissionName.remove_experiment_users });
    const canEditRoles = hasPermission(experiment, { requires: PermissionName.invite_experiment_users });
    const canTransferOwnership = canEditRoles && experiment.experiment_owner?.uuid === user?.uuid;
    const handleOwnershipChanged = async () => {
        await Promise.all([
            refreshExperiment(),
            startsWithMutate(Endpoints.lab.projects()),
            startsWithMutate(Endpoints.lab.experiments()),
        ]);
    };

    return (
        <SharingDialog
            item={experiment}
            itemType={PermissionObjectName.experiment}
            title={'Experiment sharing'}
            open={open}
            onClose={handleClose}
            roleLevels={defaultRoleLevels}
            members={members}
            membersCount={membersCount}
            membersLoading={membersLoading}
            removeMember={handleRemoveMember}
            canRemoveMembers={canRemoveMembers}
            canEditRoles={canEditRoles}
            saveMember={handleSaveMember}
            error={error}
            canTransferOwnership={canTransferOwnership}
            onOwnershipChanged={handleOwnershipChanged}
            availableMembers={availableMembers}
            handleInviteExistingUsers={handleInviteExistingUsers}
        />
    );
};

export default ExperimentSharingDialog;
