import useSWR, { KeyedMutator } from 'swr';
import useAuth from './useAuth';
import Endpoints from '@services/Endpoints';
import useApi from '@hooks/useApi';
import { useWebSocketEvent } from '@hooks/useWebSocketEvent'; // Import the WebSocket event hook
import { Notification } from '@models/notifications/Notifications';

import Logger from '@util/Logger';

interface UseNotificationsReturn {
    notifications: Notification[];
    loading: boolean;
    error: any;
    mutate: KeyedMutator<Notification[]>;
    markAsRead: (notificationId: string) => Promise<void>;
    markAllAsRead: () => Promise<void>;
    markAsUnread: (notificationId: string) => Promise<void>;
}

const useNotifications = (): UseNotificationsReturn => {
    const { user } = useAuth();
    const api = useApi();

    // SWR fetcher to get notifications
    const fetcher = async (url: string): Promise<Notification[]> => {
        const data = await api.get<Notification[]>(url);
        return data;
    };

    const key = user && api ? Endpoints.notifications.inApp() : null;

    const { data, error, mutate } = useSWR<Notification[]>(key, fetcher, {
        revalidateOnFocus: false, // Refresh data when the window regains focus
        shouldRetryOnError: false, // Retry fetching on errors
    });

    // Handle WebSocket events for real-time updates
    const logger = Logger.make('WebSocketContext');
    useWebSocketEvent('send_notification', () => {
        logger.debug('Received send_notification event:', data);
        // Refetch the notifications list when a new notification event is received
        mutate(); // Triggers a re-fetch from the API
    });

    // Functions to handle marking notifications
    const markAsRead = async (notificationId: string): Promise<void> => {
        if (!user || !api) {
            console.warn('Attempted to mark as read without an authenticated user.');
            return;
        }
        try {
            await api.put(Endpoints.notifications.markAsRead(notificationId), { is_read: true });
            mutate();
        } catch (error) {
            console.error('Error marking notification as read:', error);
        }
    };

    const markAllAsRead = async (): Promise<void> => {
        if (!user || !api) {
            console.warn('Attempted to mark all as read without an authenticated user.');
            return;
        }
        try {
            if (data) {
                await Promise.all(
                    data
                        .filter((notification) => !notification.is_read)
                        .map((notification) =>
                            api.put(Endpoints.notifications.markAsRead(notification.uuid), { is_read: true }),
                        ),
                );
                mutate();
            }
        } catch (error) {
            console.error('Error marking all notifications as read:', error);
        }
    };

    const markAsUnread = async (notificationId: string): Promise<void> => {
        if (!user || !api) {
            console.warn('Attempted to mark as unread without an authenticated user.');
            return;
        }
        try {
            await api.put(Endpoints.notifications.markAsRead(notificationId), { is_read: false });
            mutate();
        } catch (error) {
            console.error('Error marking notification as unread:', error);
        }
    };

    return {
        notifications: data || [],
        loading: !error && !data,
        error,
        mutate,
        markAsRead,
        markAllAsRead,
        markAsUnread,
    };
};

export default useNotifications;
