import { TokenResponse, useGoogleLogin } from '@react-oauth/google';
import { ApiError } from '@services/ApiError';
import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '@contexts/AuthContext';
import Logger from '@util/Logger';
import LoadingButton from '@components/LoadingButton';
import GoogleIcon from '@components/icons/GoogleIcon';
import FieldError from '@components/forms/FieldError';

const logger = Logger.make('GoogleIdentityButton');
type GoogleProfile = {
    email: string;
    email_verified: boolean;
    family_name: string;

    given_name: string;
    hd: string;
    locale: string;
    name: string;
    picture: string | null;
    sub: string;
};
type Props = {
    onConnectStarted: () => void;
    onConnectFinished?: () => void;
    onCancel?: () => void;
    login?: boolean;
    signup?: boolean;
    disabled?: boolean;
    terms_accepted?: boolean;
};
const GoogleIdentityButton = ({
    onConnectStarted,
    onConnectFinished,
    onCancel,
    login = false,
    signup = false,
    disabled = false,
    terms_accepted,
}: Props) => {
    const isLogIn = !signup && login;
    const text = isLogIn ? 'Sign in with Google' : 'Sign up with Google';
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const { connectWithGoogle } = useContext(AuthContext);

    useEffect(() => {
        if (terms_accepted) {
            setErrorMessage(null);
        }
    }, [terms_accepted]);

    const getGoogleProfile = async (
        access_token: string | undefined | null,
    ): Promise<GoogleProfile | undefined | null> => {
        try {
            const response = await fetch('https://www.googleapis.com/oauth2/v3/userinfo', {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            });
            const profile = (await response.json()) as GoogleProfile | null;
            logger.info('got profile', profile);
            return profile;
        } catch (error) {
            logger.error(error);
            return null;
        }
    };

    const handleGoogleTokenResponse = async (
        response: Omit<TokenResponse, 'error' | 'error_description' | 'error_uri'>,
    ) => {
        logger.debug('[handleGoogleResponse] google response', response);
        try {
            const profile = await getGoogleProfile(response.access_token);
            const user = await connectWithGoogle({
                isLogIn: isLogIn,
                access_token: response.access_token,
                avatar_url: profile?.picture ?? undefined,
                terms_accepted: true,
            });

            if (user) {
                logger.info('Connected with google success, doing redirect', user);
                // await loginSuccessRedirect(user);
            }
            setLoading(false);
        } catch (error) {
            logger.error(error);
            setErrorMessage(ApiError.getMessage(error as Error));
            setLoading(false);
            onCancel?.();
        } finally {
            onConnectFinished?.();
        }
    };

    /*
        Currently we use the Popup flow, but we can also support the authorization code redirect flow. 
        Uncomment the lines below to enable the redirect flow. 
        Redirecting will send a user to a Google hosted page, then back to the page /_auth/google on this app, 
        which will exchange the `code` and `scope` params for a Pluto access token 
     */
    const startGoogleLogin = useGoogleLogin({
        // flow: 'auth-code',
        // ux_mode: 'redirect',
        // redirect_uri: `${Config.web.host}/_auth/google`,
        onSuccess: (tokenResponse) => {
            // Comment out this line if we are using the redirect code flow
            handleGoogleTokenResponse(tokenResponse);
        },
        onError: (error) => {
            logger.error('onError', error);
            setLoading(false);
            onCancel?.();
        },
        onNonOAuthError: (error) => {
            logger.error('onNonOAuthError', error);
            setLoading(false);
            onCancel?.();
        },
        ...(signup ? { hosted_domain: '*' } : {}),
    });

    const handleClick = async () => {
        if (signup && !terms_accepted) {
            setErrorMessage('Please accept the Terms of Use and Privacy Policy below');
            return;
        }
        setErrorMessage(null);
        setLoading(true);
        onConnectStarted?.();
        startGoogleLogin();
    };

    return (
        <>
            <LoadingButton
                fullWidth
                variant="outlined"
                startIcon={!loading && <GoogleIcon className="mr-1" />}
                loading={loading}
                disabled={disabled || loading}
                onClick={() => handleClick()}
            >
                {text}
            </LoadingButton>
            {errorMessage && <FieldError className="mt-2 pl-2">{errorMessage}</FieldError>}
        </>
    );
};

export default GoogleIdentityButton;
