import { defaultTextLightClass, IconProps } from '@components/icons/CustomIcons';

export const SidebarRightIcon = (props?: IconProps) => {
    const { width = 16, height = 16, className = defaultTextLightClass } = props || {};
    return (
        <svg
            className={className}
            width={width}
            height={height}
            viewBox="0 0 24 24"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M15 1.25H9C3.567 1.25 1.25 3.567 1.25 9v6c0 5.433 2.317 7.75 7.75 7.75h6c5.433 0 7.75-2.317 7.75-7.75V9c0-5.433-2.317-7.75-7.75-7.75zm-.75 20H9c-4.614 0-6.25-1.636-6.25-6.25V9c0-4.614 1.636-6.25 6.25-6.25h5.25zm7-6.25c0 4.354-1.459 6.054-5.5 6.232V2.768c4.041.178 5.5 1.878 5.5 6.232zM8.53 8.91l2.56 2.56a.749.749 0 0 1 0 1.06l-2.56 2.56a.75.75 0 0 1-1.06-1.061L9.5 12 7.47 9.971A.75.75 0 0 1 8.53 8.91z" />
        </svg>
    );
};
