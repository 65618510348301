import React from 'react';
import {
    ChartBarIcon,
    SearchIcon,
    BookOpenIcon,
    CursorClickIcon,
    DocumentReportIcon,
    DatabaseIcon,
    TerminalIcon,
    RssIcon,
    ViewGridIcon,
} from '@heroicons/react/outline';

export type FlyoutItem = {
    label: string;
    href: string;
};

export type NavigationItem = {
    title: string;
    icon?: React.ReactNode; // Optional icon for the main item
    flyout?: FlyoutItem[]; // Flyout is optional
    href?: string; // Direct link action for main item
    dividerAbove?: boolean; // Optional divider above the item
    hideOnMobile?: boolean; // Hide this on mobile devices / small screens
    isNew?: boolean; // Show a new badge
};

export const navigationItems: NavigationItem[] = [
    {
        title: 'Workspace',
        icon: <ViewGridIcon className="h-5 w-5" />,
        href: '/labspace',
    },
    {
        title: 'Analysis',
        icon: <ChartBarIcon className="h-5 w-5" />,
        flyout: [
            { label: 'Projects', href: '/projects' },
            { label: 'Experiments', href: '/experiments' },
            { label: 'Plots', href: '/plots' },
        ],
    },
    {
        title: 'Research',
        icon: <BookOpenIcon className="h-5 w-5" />,
        flyout: [
            { label: 'Published data sets', href: '/explore' },
            { label: 'Literature collections', href: '/literature-datasets' },
            { label: 'GEO imports', href: '/request-experiment' },
            { label: 'Curated lists', href: '/curated-lists' },
        ],
    },
    {
        title: 'Discovery',
        icon: <CursorClickIcon className="h-5 w-5" />,
        flyout: [
            { label: 'Targets', href: '/target_lists' },
            { label: 'Biomarkers', href: '/biomarker_lists' },
            // { label: 'Molecule lists', href: '/curated-lists' },
        ],
    },
    {
        title: 'Publishing',
        icon: <DocumentReportIcon className="h-5 w-5" />,
        flyout: [
            { label: 'GEO submissions', href: '/publishing/geo-submission' },
            { label: 'Letters of support', href: '/publishing/letters-of-support' },
            { label: 'DMS templates', href: '/publishing/dms-templates' },
            { label: 'Citing Pluto', href: '/support/citing-pluto' },
        ],
    },
    {
        title: 'Data Vault',
        href: '/data',
        icon: <DatabaseIcon className="h-5 w-5" />,
        // flyout: [
        //     { label: 'Data management', href: '/data' },
        //     { label: 'Files & Attachments', href: '/biomarkers' },
        //     { label: 'Samples', href: '/curated-lists' },
        //     { label: 'Labels', href: '/curated-lists' },
        //     { label: 'Storage access', href: '/curated-lists' },
        //     { label: 'BigQuery access', href: '/curated-lists' },
        // ],
    },
    {
        title: 'Apps',
        icon: <TerminalIcon className="h-5 w-5" />,
        href: '/apps',
    },
    {
        title: 'Search',
        icon: <SearchIcon className="h-5 w-5" />,
        dividerAbove: true,
        flyout: [
            { label: 'Projects', href: '/projects' },
            { label: 'Experiments', href: '/experiments' },
            { label: 'Plots', href: '/plots' },
            { label: 'Data', href: '/data' },
            // { label: 'Curated lists', href: '/curated-lists' }, // hide for now
            // { label: 'Samples', href: '/samples' },
            { label: 'Help docs', href: '/support/knowledge-base' },
        ],
        hideOnMobile: true,
    },
    {
        title: "What's new",
        icon: <RssIcon className="h-5 w-5" />,
        href: '/release-notes',
        hideOnMobile: true,
        isNew: true,
    },
];
