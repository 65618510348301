import React, { useState, useEffect, useRef } from 'react';
import useApi from '@hooks/useApi';
import { SearchIcon } from '@heroicons/react/outline';
import { CircularProgress } from '@mui/material';
import { useRouter } from 'next/router';
import { ExperimentType } from '@/src/models/ExperimentType';
import { Organism } from '@/src/models/Organism';
import cn from 'classnames';

type QuickOmniSearchProps = {
    disabled?: boolean;
    disabledLabel?: string;
    disableSuggestion?: (suggestion: ExperimentSuggestion) => boolean;
    endpoint: string;
    hasPlots?: boolean;
    onSearch: (query: string) => void;
    onSelect?: (experimentSuggestion: ExperimentSuggestion) => void;
    placeholder?: string;
};

export type ExperimentSuggestion = {
    uuid: string;
    name: string;
    pluto_id: string;
    object_type: string;
    experiment_type?: {
        shortname?: string;
        display_name?: string;
        short_display_name?: string;
    };
    project: {
        name: string;
    };
    type: ExperimentType;
    organism: Organism;
};

type OmniSearchResponse = {
    count: number;
    next?: string | null;
    previous?: string | null;
    results: ExperimentSuggestion[];
};

const QuickOmniSearch: React.FC<QuickOmniSearchProps> = ({
    disabled,
    disabledLabel = 'Disabled',
    disableSuggestion,
    endpoint,
    hasPlots,
    onSelect,
    placeholder = 'Search...',
}) => {
    const api = useApi();
    const router = useRouter();
    const [searchQuery, setSearchQuery] = useState('');
    const [suggestions, setSuggestions] = useState<ExperimentSuggestion[]>([]);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [loading, setLoading] = useState(false);
    const [activeIndex, setActiveIndex] = useState(-1);
    const [error, setError] = useState('');
    const componentRef = useRef<HTMLDivElement>(null);

    const fetchSuggestions = async (query: string) => {
        const payload = {
            offset: 0,
            limit: 10,
            use_short: true,
            search: query,
            object_type: 'experiment',
            ...(hasPlots === true && { has_plots: true }),
        };

        setLoading(true);
        setError('');
        try {
            const data = await api.get<OmniSearchResponse>(endpoint, payload);
            setSuggestions(data.results);
        } catch (error) {
            console.error('Failed to fetch suggestions:', error);
            setError('Failed to fetch suggestions. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (searchQuery.trim()) {
                fetchSuggestions(searchQuery);
                setShowSuggestions(true);
            } else {
                setShowSuggestions(false);
                setSuggestions([]);
            }
        }, 300);

        return () => clearTimeout(delayDebounceFn);
    }, [searchQuery]);

    const handleSelectSuggestion = (suggestion: ExperimentSuggestion) => {
        setShowSuggestions(false);
        if (onSelect) {
            onSelect(suggestion);
        } else {
            router.push(`/experiments/${suggestion.pluto_id}`);
        }
        setSearchQuery('');
        setSuggestions([]);
        setActiveIndex(-1);
    };

    const handleKeyDown = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            if (activeIndex !== -1) {
                // Only allow Enter if a suggestion is focused
                handleSelectSuggestion(suggestions[activeIndex]);
            }
        } else if (e.key === 'ArrowDown') {
            e.preventDefault();
            setActiveIndex((prev) => Math.min(prev + 1, suggestions.length - 1));
        } else if (e.key === 'ArrowUp') {
            e.preventDefault();
            setActiveIndex((prev) => Math.max(prev - 1, 0));
        }
    };

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (componentRef.current && !componentRef.current.contains(event.target as Node)) {
                setShowSuggestions(false);
                setActiveIndex(-1);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className="relative w-full" ref={componentRef}>
            <div className="relative w-full max-w-96">
                <input
                    type="text"
                    value={searchQuery}
                    onChange={(e) => {
                        setSearchQuery(e.target.value);
                        setActiveIndex(-1);
                    }}
                    onKeyDown={handleKeyDown}
                    placeholder={placeholder}
                    className="field-input pl-10 !pt-1 !pb-1 w-full "
                    style={{
                        fontSize: '0.875rem',
                        borderRadius: '0.75rem',
                        paddingLeft: '2.2rem',
                    }}
                    role="combobox"
                    aria-expanded={showSuggestions}
                    aria-controls="suggestion-list"
                    aria-autocomplete="list"
                    aria-activedescendant={activeIndex >= 0 ? `suggestion-${suggestions[activeIndex].uuid}` : undefined}
                    disabled={disabled}
                />
                <div className="absolute z-50 left-3 top-1/2 transform -translate-y-1/2 text-gray-500 pointer-events-none">
                    <SearchIcon className="h-5 w-5" aria-hidden="true" />
                </div>
                {loading && (
                    <div className="absolute pt-1 z-50 right-3 top-1/2 transform -translate-y-1/2 text-gray-500 pointer-events-none">
                        <CircularProgress variant="indeterminate" size={18} />
                    </div>
                )}
            </div>
            {error && (
                <div className="absolute left-0 w-full bg-red-100 border border-red-400 text-red-700 mt-1 rounded-lg p-3 z-50">
                    {error}
                </div>
            )}
            {showSuggestions && !loading && (
                <ul
                    id="suggestion-list"
                    role="listbox"
                    className="absolute pb-2 left-0 w-full bg-white border border-gray-300 mt-1 rounded-xl shadow-lg z-50 max-h-96 overflow-auto"
                >
                    {suggestions?.length > 0 ? (
                        suggestions.map((suggestion, index) => {
                            const isDisabled = disableSuggestion?.(suggestion) || false;
                            return (
                                <li
                                    key={suggestion.uuid}
                                    id={`suggestion-${suggestion.uuid}`}
                                    role="option"
                                    aria-selected={index === activeIndex}
                                    aria-disabled={isDisabled}
                                    tabIndex={isDisabled ? -1 : 0}
                                    onMouseEnter={() => setActiveIndex(index)} // Highlight on hover
                                    onClick={() => {
                                        if (isDisabled) return;
                                        handleSelectSuggestion(suggestion);
                                    }}
                                    className={cn(
                                        `break-words px-3 py-2 pl-6 border-b ${index === activeIndex && !isDisabled ? 'bg-gray-200' : ''}`,
                                        {
                                            'opacity-50 cursor-default': isDisabled,
                                        },
                                        {
                                            'hover:bg-gray-100 cursor-pointer': !isDisabled,
                                        },
                                    )}
                                >
                                    <div className="flex flex-row items-center justify-center">
                                        <div className="flex flex-col flex-1">
                                            <div className="text-black text-sm font-semibold pt-1">
                                                {suggestion.name || 'No Experiment Name'}
                                            </div>
                                            <div className="text-xs text-gray-600 pt-1">
                                                {suggestion.experiment_type?.short_display_name && (
                                                    <>{suggestion.experiment_type.short_display_name}</>
                                                )}{' '}
                                                | {suggestion.pluto_id}
                                            </div>
                                        </div>
                                        {isDisabled && (
                                            <p className="bg-black/[.08] px-2 py-0.5 rounded-lg text-xs text-black">
                                                {disabledLabel}
                                            </p>
                                        )}
                                    </div>
                                </li>
                            );
                        })
                    ) : (
                        <div className="px-3 py-2 text-gray-600">
                            No results found, try entering a different keyword
                        </div>
                    )}
                </ul>
            )}
        </div>
    );
};

export default QuickOmniSearch;
