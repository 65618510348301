/**
 * Truncates the given text to the specified maximum length and optionally appends a "Read more" link.
 *
 * @param {string} text - The text to be truncated.
 * @param {number} maxLength - The maximum length of the truncated text.
 * @param {string} [readMoreLink=''] - An optional URL for the "Read more" link. If provided, the link will be appended to the truncated text.
 * @returns {string} The truncated text, optionally followed by a "Read more" link.
 */
export const truncateText = (text: string, maxLength: number, readMoreLink = ''): string => {
    if (text.length <= maxLength) return text;
    const truncated = text.substring(0, maxLength) + '...';
    if (readMoreLink) {
        return `${truncated} <a href="${readMoreLink}">Read more</a>`;
    }
    return truncated;
};

export const isSystemFont = (font: string) => {
    // Checks if the font is available (using a test string and size)
    return document.fonts ? document.fonts.check(`12px "${font}"`) : false;
};
