import useNotifications from '@hooks/useNotifications';
import { NotificationContent } from '@models/notifications/Notifications';
import LoadingMessage from '@components/LoadingMessage';
import React, { useState, useEffect, useRef } from 'react';
import Logger from '@util/Logger';
import Button from '@components/Button';
import ButtonGroup, { ButtonGroupItem } from '@components/ButtonGroup';
import { CheckCircleIcon } from '@heroicons/react/outline';
import JSON5 from 'json5';

const logger = Logger.make('NotificationsView');

const NotificationsView = () => {
    const { notifications, loading, markAsRead, markAllAsRead, markAsUnread } = useNotifications();
    const [view, setView] = useState<'new' | 'read'>('new');
    const [shouldBounce, setShouldBounce] = useState(false);
    const initiallyUnreadRef = useRef(false);

    const unreadCount = notifications.filter((n) => !n.is_read).length;
    const readCount = notifications.filter((n) => n.is_read).length;

    // Set initiallyUnreadRef only on the first render when notifications are loaded
    useEffect(() => {
        if (!initiallyUnreadRef.current && unreadCount > 0) {
            initiallyUnreadRef.current = true;
        }
    }, [unreadCount]);

    const displayedNotifications = (notifications ?? []).filter(
        (notification) => (view === 'new' && !notification.is_read) || (view === 'read' && notification.is_read),
    );

    // Define items for the button group
    const buttonItems: ButtonGroupItem[] = [
        {
            value: 'new',
            label: `New (${unreadCount})`,
            disabled: false,
        },
        {
            value: 'read',
            label: `Read (${readCount})`,
            disabled: false,
        },
    ];

    useEffect(() => {
        // Trigger the bounce animation only if there were unread notifications initially and now they are all read
        if (unreadCount === 0 && initiallyUnreadRef.current) {
            setShouldBounce(true);
            const timer = setTimeout(() => {
                setShouldBounce(false);
            }, 2500); // Duration for the bounce animation

            return () => clearTimeout(timer);
        }
    }, [unreadCount]);

    if (loading) {
        return (
            <div className="flex items-center justify-center py-12">
                <LoadingMessage message="Loading notifications..." />
            </div>
        );
    }

    return (
        <div>
            <div className="w-72 mx-auto">
                {/* Button Group for New and Read Notifications */}
                <ButtonGroup
                    items={buttonItems}
                    value={view}
                    onChange={(value) => setView(value as 'new' | 'read')}
                    className="mt-4 mb-4"
                />
            </div>
            {/* Conditionally render the "Mark all as read" button if there are unread notifications */}
            {view === 'new' && unreadCount > 0 && (
                <div className="flex justify-end mb-4">
                    <button
                        onClick={() => markAllAsRead()}
                        className="text-sm text-indigo-600 hover:underline"
                        disabled={displayedNotifications.length === 0}
                    >
                        Mark all as read
                    </button>
                </div>
            )}

            {/* Displayed Notifications */}
            <div className="space-y-4">
                {displayedNotifications.length > 0 ? (
                    displayedNotifications.map((notification) => {
                        let contentObject: NotificationContent | null = null;

                        try {
                            if (notification.content) {
                                // Replace single quotes with double quotes to correct JSON format
                                contentObject = JSON5.parse(notification.content) as NotificationContent;
                            } else {
                                logger.warn(
                                    `Notification content is null or undefined for notification with UUID: ${notification.uuid}`,
                                );
                            }
                        } catch (error) {
                            logger.error('Error parsing notification content:', error);
                        }

                        return (
                            <div
                                key={notification.uuid}
                                className="p-4 border border-gray-300 rounded-lg shadow-sm bg-white space-y-2"
                            >
                                {contentObject ? (
                                    <>
                                        <p className="font-medium">
                                            {contentObject.from_name} mentioned you in a comment on{' '}
                                            <strong>{contentObject.object_name}</strong>
                                        </p>
                                        <p className="bg-indigo-50 p-3 rounded-md text-sm truncate w-full overflow-hidden whitespace-nowrap">
                                            {contentObject.content}
                                        </p>
                                        <div className="flex space-x-2 pt-2">
                                            <Button
                                                href={contentObject.action_url}
                                                variant="contained"
                                                size="small"
                                                color="primary"
                                            >
                                                Reply on {contentObject.content_object}
                                            </Button>
                                            {!notification.is_read ? (
                                                <Button
                                                    onClick={() => markAsRead(notification.uuid)}
                                                    variant="outlined"
                                                    size="small"
                                                    color="secondary"
                                                >
                                                    Mark as Read
                                                </Button>
                                            ) : (
                                                <Button
                                                    onClick={() => markAsUnread(notification.uuid)}
                                                    variant="outlined"
                                                    size="small"
                                                    color="secondary"
                                                >
                                                    Mark as Unread
                                                </Button>
                                            )}
                                        </div>
                                    </>
                                ) : (
                                    <p>Error loading notification content.</p>
                                )}
                            </div>
                        );
                    })
                ) : (
                    <div className="flex flex-col items-center justify-center space-y-4 py-12">
                        <div
                            className={`rounded-full p-5 ${
                                shouldBounce ? 'animate-bounce' : ''
                            } transition-transform duration-500 ease-out`}
                            style={{ backgroundColor: '#E2FBE8' }}
                        >
                            <CheckCircleIcon className="w-11 h-11 text-green-600" />
                        </div>
                        <p className="text-lg font-semibold text-gray-700">You&apos;re all caught up!</p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default NotificationsView;
