import { defaultTextLightClass, IconProps } from '@components/icons/CustomIcons';

export const SidebarLeftIcon = (props?: IconProps) => {
    const { width = 16, height = 16, className = defaultTextLightClass } = props || {};
    return (
        <svg
            className={className}
            width={width}
            height={height}
            viewBox="0 0 24 24"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M15 1.25H9c-.3 0-.594.01-.879.024A.81.81 0 0 0 8 1.25a.732.732 0 0 0-.261.053C3.213 1.655 1.25 4.011 1.25 9v6c0 4.989 1.963 7.345 6.489 7.7a.732.732 0 0 0 .261.05.81.81 0 0 0 .121-.024c.285.014.575.024.879.024h6c5.432 0 7.75-2.317 7.75-7.75V9c0-5.433-2.318-7.75-7.75-7.75zm-7.75 19.9c-3.282-.414-4.5-2.175-4.5-6.148V9c0-3.973 1.218-5.734 4.5-6.148zm14-6.15c0 4.614-1.636 6.25-6.25 6.25H9c-.087 0-.165 0-.25-.006V2.756c.085 0 .163-.006.25-.006h6c4.614 0 6.25 1.636 6.25 6.25zm-5.72-5.029L13.5 12l2.029 2.029a.75.75 0 1 1-1.06 1.061l-2.56-2.56a.749.749 0 0 1 0-1.06l2.56-2.56a.75.75 0 0 1 1.06 1.061z" />
        </svg>
    );
};
