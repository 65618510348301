import React, { useEffect, useState, useRef, useContext } from 'react';
import { AuthContext } from '@contexts/AuthContext';
import { BellIcon } from '@heroicons/react/outline';
import useNotifications from '@hooks/useNotifications';
import { useLabSpaceContext } from '@contexts/LabSpaceContext';
import { useWebSocket } from '@contexts/WebSocketContext';
import Link from 'next/link';

const NotificationBell = () => {
    const { user } = useContext(AuthContext);
    const highlightColor = user?.organization?.branding?.highlight_color || '#7E51A9';
    const { notifications } = useNotifications(); // Notifications automatically update via WebSocket and API refetch
    const { setNotificationsModalOpen } = useLabSpaceContext();
    const { connected } = useWebSocket();

    const [shake, setShake] = useState(false);
    const unreadCountRef = useRef(0);

    // Calculate the number of unread notifications
    const unreadCount = notifications.filter((notification) => notification && !notification.is_read).length;

    useEffect(() => {
        if (unreadCountRef.current < unreadCount) {
            setShake(true);
            setTimeout(() => setShake(false), 1000); // Reset the shake after 1 second
        }

        unreadCountRef.current = unreadCount;
    }, [unreadCount]);

    // Determine opacity based on connection and unread status
    const opacityClass = !connected
        ? 'opacity-20' // Not connected: even lower opacity
        : unreadCount === 0
          ? 'opacity-70' // Connected but no unread: medium opacity
          : ''; // Connected and has unread: full opacity

    return (
        <Link
            href="#"
            className="relative flex items-center"
            onClick={(e) => {
                e.preventDefault(); // Prevent default link behavior
                setNotificationsModalOpen(true);
            }}
        >
            <BellIcon
                aria-label="Notifications"
                className={`w-6 h-6 transition-transform text-white hover:text-white ${opacityClass} ${shake ? 'animate-shake' : ''}`}
            />
            {unreadCount > 0 && (
                <span
                    className="absolute top-0 right-0 inline-flex items-center justify-center px-1.5 py-0.5 text-xs font-bold leading-none text-white transform translate-x-1/2 -translate-y-1/2 rounded-full"
                    style={{
                        backgroundColor: highlightColor,
                    }}
                >
                    {unreadCount}
                </span>
            )}
        </Link>
    );
};

export default NotificationBell;
