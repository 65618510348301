// hooks/usePendingInvites.ts

import useSWR from 'swr';
import useApi from '@hooks/useApi';
import Endpoints from '@services/Endpoints';
import { Invite } from '@models/Invitation';

const usePendingInvites = () => {
    const api = useApi();

    const fetcher = async (url: string) => {
        const data = await api.get<Invite[]>(url);
        return data;
    };

    const { data, error, mutate } = useSWR(Endpoints.user.invites(), fetcher, {
        refreshInterval: 180000, // Revalidate every 3 minutes
        dedupingInterval: 60000, // Prevent duplicate requests within 1 minute
    });

    return {
        invites: data,
        loading: !error && !data,
        error,
        mutate,
    };
};

export default usePendingInvites;
