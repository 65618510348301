import React, { useEffect, useRef } from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';
import UserAvatar from '@components/auth/UserAvatar';
import { User } from '@models/User';
import { Organization } from '@models/Organization';
import { OfficeBuildingIcon } from '@heroicons/react/outline';
import cn from 'classnames';
import { hasPermission } from '@util/PermissionUtil';
import { PermissionName } from '@models/Permission';

interface BannerFlyoutProps {
    user: User;
    organization?: Organization;
    organizations?: Organization[];
    onClose: () => void;
}

const BannerFlyout: React.FC<BannerFlyoutProps> = ({ user, organization, organizations, onClose }) => {
    const branding = organization?.branding;
    const highlightColor = branding?.highlight_color || '#7E51A9';
    const nav_menu_hover = branding?.nav_menu_hover;
    const router = useRouter();
    const otherOrganizations = organization ? organizations?.filter((org) => org.uuid !== organization.uuid) : [];
    const canEditRoles = organization
        ? hasPermission(organization, { requires: PermissionName.invite_organization_users })
        : false;

    const handleSelectOrg = async (org: Organization) => {
        await router.push({ pathname: '/labspace', query: { organization: org.org_slug } });
        location.reload();
    };

    const flyoutRef = useRef<HTMLDivElement>(null);

    // Timeout ref for delayed close
    const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

    const handleMouseEnter = () => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
            timeoutRef.current = null;
        }
    };

    const handleMouseLeave = () => {
        timeoutRef.current = setTimeout(() => {
            onClose();
        }, 275);
    };

    // Clean up the timeout on unmount
    useEffect(() => {
        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };
    }, []);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (flyoutRef.current && !flyoutRef.current.contains(event.target as Node)) {
                onClose();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [onClose]);

    const SectionDivider = () => (
        <div
            className="border-t my-0 mt-3 mx-0"
            style={{
                borderColor: highlightColor,
                borderWidth: '1px',
                opacity: 0.75,
            }}
        ></div>
    );

    const MenuItem: React.FC<{ href?: string; label: string; onClick?: () => void; hideOnMobile?: boolean }> = ({
        href,
        label,
        onClick,
        hideOnMobile,
    }) => {
        const handleClick = () => {
            if (onClick) onClick();
            onClose();
        };

        return (
            <li
                className={cn(
                    'flyout-menu hover:bg-pluto-layout-blue-hover py-2 pl-8 cursor-pointer',
                    hideOnMobile ? 'hidden md:block' : '',
                )}
            >
                {href ? (
                    <Link
                        href={href}
                        className="flex items-center w-full h-full text-white hover:text-white"
                        onClick={handleClick}
                    >
                        {label}
                    </Link>
                ) : (
                    <span className="text-white" onClick={handleClick}>
                        {label}
                    </span>
                )}
            </li>
        );
    };

    return (
        <div
            ref={flyoutRef}
            className="absolute top-14 right-3 mt-2 w-80 bg-pluto-layout-blue rounded-lg pb-4 shadow-lg z-50 text-white"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            style={{
                ...(branding?.banner_background && { backgroundColor: branding.banner_background }),
            }}
        >
            {nav_menu_hover && (
                <style>{`
                    .flyout-menu:hover {
                        background-color: ${nav_menu_hover} !important;
                    }
                `}</style>
            )}
            {/* User Section */}
            <div className="pt-6">
                <div className="flex items-center gap-3 mb-3 pl-8">
                    <UserAvatar size="md" widthClass="w-8" heightClass="h-8" diameter="100%" />
                    <div>
                        <div className="font-semibold text-white text-sm">
                            {user.first_name} {user.last_name}
                        </div>
                        <div className="text-xs text-white">{user.email}</div>
                    </div>
                </div>
                <ul className="space-y-0 text-sm">
                    <MenuItem href="/account" label="Account settings" />
                </ul>
            </div>
            {/* Organization Section (render only if an organization exists) */}
            {organization && (
                <>
                    <SectionDivider />
                    <div className="pl-8 mt-4">
                        <div className="flex items-center gap-3">
                            <div
                                className="w-8 h-8 rounded-full flex items-center justify-center text-white font-bold"
                                style={{ backgroundColor: '#7E51A9' }}
                            >
                                {organization.name[0]}
                            </div>
                            <div>
                                <div className="text-sm font-semibold text-white">{organization.name}</div>
                                <div className="text-xs text-white">{organization.org_slug}</div>
                            </div>
                        </div>
                    </div>
                </>
            )}
            {/* Navigation Links (only if organization exists and user has permissions) */}
            {organization && canEditRoles && (
                <>
                    <SectionDivider />
                    <div className="pt-4">
                        <ul className="space-y-0 text-sm">
                            <MenuItem href="/organization" label="Organization settings" hideOnMobile={true} />
                            <MenuItem href="/team" label="Users & Teams" hideOnMobile={true} />
                            <MenuItem href="/metrics" label="Usage metrics" />
                            {(user.organization_role ?? '').toLowerCase() === 'owner' && (
                                <MenuItem
                                    href="/organization/subscription"
                                    label="Plan & Billing"
                                    hideOnMobile={true}
                                />
                            )}
                        </ul>
                    </div>
                </>
            )}
            {/* Other Organizations Section (only if organization exists and there are alternatives) */}
            {organization && otherOrganizations && otherOrganizations.length > 0 && (
                <>
                    <SectionDivider />
                    <div className="mt-4">
                        <div className="flex items-center gap-3 pl-8 mb-2">
                            <div
                                className="w-8 h-8 rounded-full flex items-center justify-center text-white font-bold"
                                style={{
                                    border: `2px solid ${highlightColor}`,
                                    backgroundColor: 'transparent',
                                }}
                            >
                                <OfficeBuildingIcon className="h-4 w-4" />
                            </div>
                            <div className="font-semibold text-white text-sm">Switch organization to</div>
                        </div>
                        <ul className="space-y-0 text-sm">
                            {otherOrganizations.map((org) => (
                                <li
                                    key={org.uuid}
                                    className="flyout-menu hover:bg-pluto-layout-blue-hover py-3 pl-8 cursor-pointer"
                                    onClick={() => handleSelectOrg(org)}
                                >
                                    <span className="text-white">{org.name}</span>
                                </li>
                            ))}
                        </ul>
                    </div>
                </>
            )}
            <SectionDivider />
            {/* Sign Out & Privacy Policy */}
            <div className="mt-4">
                <div className="flex justify-between px-8 text-sm">
                    <Link href="/logout" className="text-white hover:text-white">
                        Sign out
                    </Link>
                    <Link
                        href="https://pluto.bio/legal/privacy-policy"
                        target="_blank"
                        className="text-white hover:text-white"
                    >
                        Privacy Policy
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default BannerFlyout;
